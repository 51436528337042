<!--
 * @Description: 耗材自动出库配置详情
 * @Author: ChenXueLin
 * @Date: 2021-10-19 14:46:30
 * @LastEditTime: 2022-07-01 09:27:33
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="详情"
    :visible="detailVisible"
    width="700px"
    :close-on-click-modal="false"
    :element-loading-background="loadingBackground"
    :before-close="handleClose"
    custom-class="install-scene-dialog"
  >
    <el-form label-width="120px" ref="detailInfo" :model="detailInfo">
      <el-form-item label="单据类型">
        {{ detailInfo.consumeTypeName }}
      </el-form-item>
      <el-form-item label="工单类型">
        {{ detailInfo.workOrderTypeNameListStr }}
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch
          v-model="detailInfo.status"
          disabled
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
    </el-form>
    <div>
      <!-- 工单类型+具体耗材 -->
      <el-table
        border
        :data="materialVOList"
        highlight-current-row
        ref="selectTable"
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import { getConsumeMaterialDetail } from "@/api";
export default {
  name: "detail",
  components: {},
  data() {
    return {
      materialVOList: []
    };
  },
  props: ["detailVisible", "detailInfo"],
  mixins: [base],
  computed: {
    columnData() {
      let data = [
        {
          fieldName: "materialNoName",
          fieldLabel: "耗材名称",
          width: 200,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          fieldLabel: "耗材编码",
          width: 100,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "amount",
          fieldLabel: "数量",
          width: 100,
          fixed: false,
          align: "center"
        }
      ];
      if (this.detailInfo.consumeType == 1) {
        data.unshift({
          fieldName: "bizNoNameListStr",
          fieldLabel: "物料名称",
          width: 200,
          fixed: false,
          align: "center"
        });
      }
      return data;
    }
  },
  watch: {
    detailVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getDetail();
        }
      }
    }
  },
  created() {},
  methods: {
    async getDetail() {
      try {
        this.dialogLoading = true;
        let res = await getConsumeMaterialDetail({
          id: this.detailInfo.consumeId
        });
        if (res.code == "OK") {
          let materialVOList = res.data.materialVOList;
          materialVOList.map(item => {
            item.bizNoNameListStr = item.bizNoNameList.join(",");
          });
          this.materialVOList = materialVOList;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.$emit("close", "detailVisible");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.install-scene-dialog {
  .el-dialog__body {
    padding-top: 20px;
    [class^="e6-icon"] {
      height: 28px;
      color: #46bfea;
      line-height: 28px;
      font-style: normal;
      text-align: center;
      padding: 0 10px;
      cursor: pointer;
      border-radius: 3px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      padding: 0;
      font-size: 20px;
    }
  }
}
</style>
