<!--
 * @Description: 耗材自动出库配置
 * @Author: ChenXueLin
 * @Date: 2021-09-09 16:10:01
 * @LastEditTime: 2022-07-15 16:09:44
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="workOrderTypeList">
              <e6-vr-select
                v-model="searchForm.workOrderTypeList"
                :data="consumeMaterialWorkTypeList"
                placeholder="工单类型"
                title="工单类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                multiple
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialNo">
              <el-input
                v-model="searchForm.materialNo"
                placeholder="耗材编码"
                title="耗材编码"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="materialNoList">
              <remote-search
                v-model="searchForm.materialNoList"
                :remote="true"
                clearable
                placeholder="耗材名称"
                title="耗材名称"
                multiple
                :searchData="{
                  materialTypes: [408702, 408703],
                  pageIndex: 1,
                  pageSize: 200
                }"
              ></remote-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="status">
              <e6-vr-select
                v-model="searchForm.status"
                :data="statusList"
                placeholder="单据状态"
                title="单据状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="consumeType">
              <e6-vr-select
                v-model="searchForm.consumeType"
                :data="consumeTypeList"
                placeholder="单据类型"
                title="单据类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"> </i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="handleEnable(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="handleEnable(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          @selection-change="handleSelectionChange"
          highlight-current-row
          ref="elTable"
          row-key="consumeDetailId"
          :cell-class-name="isRender"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="80"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.row.sortNum }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
    <!-- 新增、编辑 -->
    <add
      :addVisible="addVisible"
      @close="closeDialog"
      :detailInfo="detailInfo"
      :consumeTypeList="consumeTypeList"
      :consumeMaterialWorkTypeList="consumeMaterialWorkTypeList"
      :classifyList="classifyList"
      :editType="editType"
      @refresh="queryList"
    ></add>
    <!-- 详情 -->
    <detail
      :detailVisible="detailVisible"
      @close="closeDialog"
      :detailInfo="detailInfo"
    ></detail>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getAutoConsumeMaterialList,
  findDownList,
  getMaterialClassifyList,
  updateStatusAutoConsumeMaterial
} from "@/api";
import { printError } from "@/utils/util";
import add from "./components/add.vue";
import Detail from "./components/detail.vue";
export default {
  name: "consumableDeliveryList",
  components: { add, Detail },
  data() {
    return {
      total: 0,
      loading: false,
      projectStatusList: [], //项目状态
      searchForm: {
        consumeType: "",
        status: "",
        workOrderTypeList: [],
        materialNoList: [],
        materialNo: "",
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "consumeTypeName",
          display: true,
          fieldLabel: "单据类型",
          width: 100,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "workOrderTypeNameListStr",
          display: true,
          fieldLabel: "工单类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "bizNoName",
          display: true,
          fieldLabel: "物料分类",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNo",
          display: true,
          fieldLabel: "耗材编码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "materialNoName",
          display: true,
          fieldLabel: "耗材名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "amount",
          display: true,
          fieldLabel: "耗材数量",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },

        {
          fieldName: "statusName",
          display: true,
          fieldLabel: "单据状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdBy",
          display: true,
          fieldLabel: "操作人",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      consumeMaterialWorkTypeList: [],
      statusList: [
        { id: 0, label: "禁用" },
        { id: 1, label: "启用" }
      ],
      consumeTypeList: [],
      tableData: [],
      taskTypeList: [],
      selColumnId: [],
      classifyList: [],
      refTable: "elTable",
      queryListAPI: getAutoConsumeMaterialList,
      addVisible: false,
      detailVisible: false,
      detailInfo: {},
      editType: ""
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    isRender(row) {
      if (!row.row.isFirst) {
        return "disabled-column";
      }
    },
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [
          findDownList([
            "consumeMaterialWorkType",
            "consumeType",
            "consumeMasterType"
          ]),
          getMaterialClassifyList({})
        ];
        let [projectStatusRes, classifyRes] = await Promise.all(promiseList);
        this.consumeMaterialWorkTypeList = this.getFreezeResponse(
          projectStatusRes,
          {
            path: "data.consumeMaterialWorkType"
          }
        );
        this.consumeTypeList = this.getFreezeResponse(projectStatusRes, {
          path: "data.consumeType"
        });
        this.consumeMasterTypeList = this.getFreezeResponse(projectStatusRes, {
          path: "data.consumeMasterType"
        });
        this.classifyList = this.getFreezeResponse(classifyRes, {
          path: "data.array"
        });
      } catch (error) {
        printError(error);
      }
    },
    //格式化tableData
    formatList(data) {
      data.map((item, index) => {
        let startIndex =
          (this.searchForm.pageIndex - 1) * this.searchForm.pageSize;
        item.workOrderTypeNameListStr = item.workOrderTypeNameList.join(",");
        if (item.materialVOList.length) {
          item.children = item.materialVOList.filter((item, idx) => idx != 0);
          item.consumeId = item.materialVOList[0].consumeId;
          item.consumeDetailId = item.materialVOList[0].consumeDetailId;
          item.bizNoName = item.materialVOList[0].bizNoName;
          item.bizNo = item.materialVOList[0].bizNo;
          item.isFirst = true;
          item.amount = item.materialVOList[0].amount;
          item.materialNo = item.materialVOList[0].materialNo;
          item.materialNoName = item.materialVOList[0].materialNoName;
          item.workOrderType = item.materialVOList[0].workOrderType;
        } else {
          item.children = [];
        }
        item.sortNum = startIndex + index + 1;
      });
      return data;
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return item.consumeId;
      });
    },
    // 获取页面操作权限
    getOperateList(row) {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1,
          disabled: !row.isFirst
        },
        {
          label: "编辑",
          id: 2,
          disabled: !row.isFirst
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.detailVisible = true;
        this.detailInfo = row;
      }
      if (val.id == 2) {
        this.addVisible = true;
        this.detailInfo = row;
        this.editType = 2;
      }
    },
    handleAdd() {
      this.addVisible = true;
      this.editType = 1;
    },
    closeDialog(prop) {
      this[prop] = false;
    },
    //点击启用、禁用
    handleEnable(type) {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择要操作的数据");
        return;
      } else {
        let confirmTitleKeyWord = type == 1 ? "启用" : "禁用";
        this.$confirm(
          `是否确认${confirmTitleKeyWord}所选配置?`,
          `${confirmTitleKeyWord}配置`,
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            this.enableProp(type);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: `已取消${confirmTitleKeyWord}`
            });
          });
      }
    },
    async enableProp(status) {
      try {
        this.loading = true;
        let res = await updateStatusAutoConsumeMaterial({
          consumeIdList: this.selColumnId,
          status
        });
        if (res.code == "OK") {
          let title = status ? "启用成功" : "禁用成功";
          this.$message.success(title);
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
/* 隐藏多选框 */
/deep/.disabled-column .el-checkbox__input {
  display: none !important;
}
</style>
